import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormHelperText, Stack, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
// translation
import { useLocales } from '../../locales';
export default function RHFDate({ name, inputFormat, helperText, multiline, rows, type, inputRef, id, label, isDisabled, views, minDate, maxDate, ...other }) {
    const { control, setValue } = useFormContext();
    const { translate } = useLocales();
    const today = new Date();
    today.setHours(0, 0, 0);
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsxs(Stack, { sx: { display: 'flex', flexDirection: 'column' }, children: [_jsx(Tooltip, { title: `${translate(helperText)}` || `${translate(label)}`, children: _jsx(DatePicker, { maxDate: maxDate, minDate: minDate, views: views, label: `${translate(label)} `, disabled: isDisabled, value: field.value ? new Date(field?.value) : null, onOpen: () => {
                            setValue(name, field.value ? new Date(field?.value) : new Date(today));
                        }, sx: {
                            '& .MuiInputLabel-root': {
                                color: typeof error?.message === 'string' ? '#FF5630' : undefined,
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: typeof error?.message === 'string' ? '#FF5630' : undefined,
                                },
                                '&:hover fieldset': {
                                    borderColor: typeof error?.message === 'string' ? '#FF5630' : undefined,
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'black',
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: 'black',
                                },
                            },
                        }, onChange: (newValue) => {
                            field.onChange(newValue);
                        }, format: inputFormat || 'dd/MM/yyyy' }) }), (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, sx: { ml: 2 }, children: error ? `${translate(error?.message)}` : `${translate(helperText)}` }))] })) }));
}
